import React from "react"
import Layout from "../../../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import PartnerCarousel from "../../../components/PartnerCarousel"
import PeaceOfMindGuarantee from "../../../components/PeaceOfMindGuarantee"
import SectionTitle from "../../../components/SectionTitle"
import Services from "../../../components/Services"
import { Banner } from "../../../components/Banner"
import BusinessOffers from "../../../components/business/BusinessOffers"
import SEO from "../../../components/seo"

export default function PresentationEquipment() {
  const data = useStaticQuery(graphql`
    query businessPresentationEquipment {
      Projectors: file(
        relativePath: { eq: "services/AdobeStock_86694854.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      InteractiveTechnology: file(
        relativePath: { eq: "services/AdobeStock_234479733.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      BannerImage: file(
        relativePath: { eq: "banner/AdobeStock_207885558.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Projectors",
      description:
        "A good business projector is vitally important if you regularly make presentations. Bright and vibrant projections allow you to share your screen with an audience, while keeping them engaged with your presentation or meeting",
      image: data.Projectors.childImageSharp.fixed,
    },
    {
      title: "Interactive Touchscreen Solutions",
      description:
        "Enhance your conference or meeting room with the ability to use the powerful software within your computer, including Video Conferencing, Presenting, Interactive Mind Mapping, Annotation and much more",
      image: data.InteractiveTechnology.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="business">
      <SEO title="Compulease · Presentation Equipment Business" />
      <BusinessOffers />
      <Container>
        <SectionTitle title="Presentation Equipment" />
        <Services
          title="
                Compulease™ will install a leading brand solution that will deliver reliability,
                performance and quality"
          services={services}
        />
      </Container>
      <Banner
        image={data.BannerImage.childImageSharp.fluid}
        title="Installation & Warranty"
        description="All our solutions can be supplied with full installation and in most cases there are optional warranty upgrades available"
        link={{
          text: "Get in Touch",
          to: "/business/ContactUs/",
        }}
      />
      <Container>
        <PeaceOfMindGuarantee />
        <PartnerCarousel type="business" />
      </Container>
    </Layout>
  )
}
